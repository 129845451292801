import { getRoute, PATH } from '@/utils/routes';
import router from 'next/router';
import { useSelector } from 'react-redux';

export function useCollectionClick(toggleDropdown) {
  const { storeData } = useSelector(state => ({
    storeData: state.storeReducer.store,
  }));

  const handleCollectionClick = collection => {
    router.push(
      getRoute(
        `${PATH.collectionCategories(collection.id)}`,
        storeData?.store_info?.domain
      )
    );
    /*
      if toggleDropdown !== undefined, then close header dropdown
      only for mobile usage
    */
    toggleDropdown?.();
  };

  return [handleCollectionClick];
}

import { DEFAULT_COLLECTION_IMAGE } from '@/utils/constants';
import { useCollectionClick } from './useCollectionClick';
import { useSSRSelector } from '@/redux/ssrStore';

export const CollectionHeaderDropdownWidget1 = ({
  classes = '',
  toggleDropdown = undefined,
}) => {
  const [handleCollectionClick] = useCollectionClick(toggleDropdown);

  const { collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
  }));

  return (
    <section className={`collection-list-container__body ${classes}`}>
      {collections
        ?.filter((collection) => collection.id !== 0)
        .map((collection, index) => (
          <div
            className="collection-item"
            onClick={() => handleCollectionClick(collection)}
            key={collection.id}
          >
            <div className="collection-image">
              <img
                alt={`collection ${index + 1}`}
                src={collection.image_url || DEFAULT_COLLECTION_IMAGE[index]}
              />
            </div>
            <p className="collection-name ttu">{collection.name}</p>
          </div>
        ))}
    </section>
  );
};

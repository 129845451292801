import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { CollectionHeaderDropdownWidget1 } from './CollectionHeaderDropdownWidget1';
import { CollectionHeaderDropdownWidget3 } from './CollectionHeaderDropdownWidget3';
import { UserIconDark } from '@/assets/svgExports/UserIconDark';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { useEffect, useState } from 'react';
import { executeLogout } from '@/utils/excuteLogout';
import LogoutModal from '@/components/LogoutModal';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { useSSRSelector } from '@/redux/ssrStore';
import useInstallAppOverlayActions from '@/components/InstallAppInstructionsOverlay/useInstallAppOverlayActions';
import checkIfWebview from '@/utils/checkIfWebview';
import { showMyAccount } from '@/utils/getNavList';
const InstallAppInstructionsOverlay = dynamic(
  () => import('@/components/InstallAppInstructionsOverlay'),
  {
    ssr: false,
  }
);

const CollectionHeaderDropdown = ({
  navItemsState,
  renderNavType,
  openCollections,
  goBack,
  collectionListType,
  toggleDropdown,
  isLoginVisible,
  headerName,
}) => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const { userLogin } = useSelector((state) => ({
    userLogin: state.userReducer.isLogin,
  }));
  const { storeData, storeCollections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    storeCollections: state.catalogReducer.collections,
  }));

  const { installAppFeatureStatus } = useFeatureLocks();
  const { openIntallAppOverlay } = useInstallAppOverlayActions();
  const isWebview = checkIfWebview();

  useEffect(() => {
    if (showLogoutConfirmation) setShowLogoutConfirmation(false);
  }, [userLogin]);

  const toggleAuthModal = () => {
    if (userLogin) {
      toggleLogoutModal();
    } else {
      router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
    }
  };

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  const renderMobileLoginLogout = () => {
    if (headerName === 'header14' && !isLoginVisible) {
      return null;
    }
    return (
      <div className="flex items-center justify-between mt3" onClick={toggleAuthModal}>
        <div className="flex items-center">
          <div className="mr3">
            <UserIconDark
              color={
                storeData?.theme?.colors?.primary_color !== '#ffffff'
                  ? '#000000'
                  : '#ffffff'
              }
              width="18"
              height="18"
            />
          </div>
          <span className={userLogin ? 'red' : 'black'}>
            {userLogin ? 'Logout' : 'Login'}
          </span>
        </div>
      </div>
    );
  };

  const renderCollectionList = () => {
    switch (collectionListType) {
      case 'CollectionHeaderDropdownWidget1':
        return (
          <CollectionHeaderDropdownWidget1
            classes="collection-header-widget-1"
            toggleDropdown={toggleDropdown}
          />
        );
      case 'CollectionHeaderDropdownWidget2':
        return (
          <CollectionHeaderDropdownWidget1
            classes="collection-header-widget-2"
            toggleDropdown={toggleDropdown}
          />
        );
      case 'CollectionHeaderDropdownWidget3':
        return <CollectionHeaderDropdownWidget3 toggleDropdown={toggleDropdown} />;
      default:
        return (
          <CollectionHeaderDropdownWidget1
            classes="collection-header-widget-1"
            toggleDropdown={toggleDropdown}
          />
        );
    }
  };

  return (
    <>
      <div className={`dropdown ${openCollections ? 'active' : ''}`}>
        <div className="nav-state">
          {navItemsState?.length > 0 && (
            <ul className="dropdown--menu w-100">
              {navItemsState?.map((page) => renderNavType(page, true))}
              {!isWebview && installAppFeatureStatus?.isVisible && (
                <li
                  className="menu-item black pwa:tw-hidden"
                  onClick={() => {
                    openIntallAppOverlay();
                  }}
                >
                  <div>Install App</div>
                </li>
              )}
              {!showMyAccount() && (
                <li
                  className="menu-item"
                  style={{
                    borderTop: '1px solid #ebebeb',
                  }}
                >
                  {renderMobileLoginLogout()}
                </li>
              )}
            </ul>
          )}
        </div>
        <div className="collection-list-container">
          <div className="collection-list-container__header">
            <p onClick={goBack} className="flex items-center">
              <img
                src="/assets/images/down-arrow-thin.svg"
                alt="dropdown button"
                className="mr3"
                style={{
                  width: 13,
                  transform: `rotate(90deg)`,
                }}
              />
              Shop
            </p>
          </div>
          {storeCollections?.length ? renderCollectionList() : null}
        </div>
      </div>
      <InstallAppInstructionsOverlay />
      {showLogoutConfirmation && (
        <LogoutModal
          toggleLogoutModal={toggleLogoutModal}
          executeLogout={executeLogout}
          showLogoutConfirmation={showLogoutConfirmation}
        />
      )}
    </>
  );
};

export default CollectionHeaderDropdown;

import { useState } from 'react';
import { useCollectionClick } from './useCollectionClick';
import { useSSRSelector } from '@/redux/ssrStore';

export const CollectionHeaderDropdownWidget3 = ({ toggleDropdown }) => {
  const [handleCollectionClick] = useCollectionClick(toggleDropdown);
  const [accordionId, setAccordionId] = useState(null);

  const { collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
  }));

  const accordionHeaderClick = (e) => {
    e.stopPropagation();
    const dataId = e.target.getAttribute('data-id');
    if (+dataId === accordionId) {
      setAccordionId(null);
      return;
    } else if (+dataId) {
      setAccordionId(+dataId);
    }
  };

  return (
    <section className="collection-list-container__body collection-header-widget-3">
      {collections?.length ? (
        <div className="collection-accordion">
          {/* accordion heading */}
          {collections?.map((collection) => (
            <div className="collection-accordion__container" key={collection.id}>
              <div
                className="flex items-center justify-between collection-accordion__header pointer"
                data-id={collection.id}
                onClick={accordionHeaderClick}
              >
                <p className="mv0 z-1" onClick={accordionHeaderClick}>
                  {collection.name}
                </p>
                <img
                  data-id={collection.id}
                  style={{
                    transform: `rotate(${
                      accordionId === collection.id ? '180deg' : '0deg'
                    })`,
                  }}
                  alt="dropdown button"
                  src="/assets/images/down-arrow-thin.svg"
                  className="z-0"
                />
              </div>
              {/* accordion body */}
              {accordionId === collection.id && (
                <div className="collection-accordion__body">
                  <ul className="body-list">
                    {collection?.categories?.map((category) => (
                      <li
                        key={category.id}
                        className="pointer body-list--item"
                        onClick={() => handleCollectionClick(collection)}
                      >
                        {category.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </section>
  );
};
